
<div>
  <b-row>
    <SelectField
      v-model="documentFolder"
      name="document_folder_id"
      :label="__('documents.folder')"
      :options="documentFolders"
      class="col-12"
    />
  </b-row>

  <div class="text-right">
    <b-button size="sm" class="mr-2" variant="primary" @click="submit">
      <Icon icon="save" class="mr-2" size="14" />
      {{ __('buttons.save') }}
    </b-button>

    <b-button size="sm" variant="outline-primary" @click="closeModal()">
      <Icon icon="times" class="mr-2" size="14" />
      {{ __('buttons.cancel') }}
    </b-button>
  </div>
</div>
