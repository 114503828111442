<template>
  <div>
    <b-row>
      <TextField
        v-model="documentFolder.name"
        name="name"
        :label="__('documents.folder-name')"
        class="col-12"
        required
      />
    </b-row>

    <div class="text-right">
      <b-button size="sm" class="mr-2" variant="primary" @click="submit">
        <Icon icon="save" class="mr-2" size="14" />
        {{ __('buttons.save') }}
      </b-button>

      <b-button size="sm" variant="outline-primary" @click="closeModal()">
        <Icon icon="times" class="mr-2" size="14" />
        {{ __('buttons.cancel') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import ModalMixin from '@/General/Modal/ModalMixin.js'
import DocumentModel from '@/Modules/Document/DocumentModel.js'
import QuoteDocumentService from '@/Modules/Quote/QuoteDocument/QuoteDocumentService.js'

export default {
  name: 'EditDocumentFolderModal',

  mixins: [ModalMixin],

  props: {
    meta: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },

  data() {
    return {
      DocumentModel,
      documentFolder: new DocumentModel(this.meta.documentFolder || {}),
    }
  },

  created() {
    this.$nextTick(() => this.errors.clear())
  },

  methods: {
    submit() {
      this.$nextTick(() => this.errors.clear())
      if (this.documentFolder.id) {
        QuoteDocumentService.updateFolder(
          this.meta.quoteId,
          this.documentFolder.id,
          this.getDocumentFolderPayload()
        )
          .then(() => {
            this.onSuccess && this.onSuccess()
            this.closeModal(this.__('documents.folder-update-success'))
          })
          .catch((error) => this.setErrorsFromResponse(error))

        return
      }

      QuoteDocumentService.createFolder(
        this.meta.quoteId,
        this.getDocumentFolderPayload()
      )
        .then((data) => {
          this.onSuccess && this.onSuccess(data)
          this.closeModal(this.__('documents.folder-create-success'))
        })
        .catch((error) => this.setErrorsFromResponse(error))
    },

    getDocumentFolderPayload() {
      let data = {
        name: this.documentFolder.name,
      }

      if (this.meta.parentFolderId) {
        data.parent_folder_id = this.meta.parentFolderId
      }

      return data
    },
  },
}
</script>
