
<div>
  <b-row>
    <TextField
      v-model="documentFolder.name"
      name="name"
      :label="__('documents.folder-name')"
      class="col-12"
      required
    />
  </b-row>

  <div class="text-right">
    <b-button size="sm" class="mr-2" variant="primary" @click="submit">
      <Icon icon="save" class="mr-2" size="14" />
      {{ __('buttons.save') }}
    </b-button>

    <b-button size="sm" variant="outline-primary" @click="closeModal()">
      <Icon icon="times" class="mr-2" size="14" />
      {{ __('buttons.cancel') }}
    </b-button>
  </div>
</div>
